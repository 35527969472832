const paragraph = [
  {
    language: { name: "Türkçe", code: "tr" },
    header: "Biz Kimiz? Ne Yapıyoruz?",
    paragraph: {
      __html:
        "<p>Afet Yönetim Sistemi, afet bölgelerinde gönüllü yönetimini ve yardım koordinasyonunu sağlayan bir platformdur. Amacımız, afet sırasında ve sonrasında gönüllülerin etkin bir şekilde organize edilmesi ve yardım faaliyetlerinin doğru ve güvenli bir şekilde gerçekleştirilmesidir. Bu platform sayesinde, afet sonrası yardımların hızlı ve etkili bir şekilde ihtiyaç sahiplerine ulaşması sağlanacaktır.</p>\n" +
        "<p>Platformumuz aynı zamanda kurumların doğrudan etkileşimde bulunabilecekleri açık bir yapı sunmaktadır. Kurumlar bu platforma dahil olarak, nitelikli gönüllü portföylerine erişebilir ve etkinlikler sonrası değerlendirme yapabilirler. Bu sayede, afet yönetim süreçlerinde daha etkin ve organize bir yapı oluşturulması hedeflenmektedir.</p>\n" +
        "<p>Projeye katkı sağlamak, repositoryleri incelemek ve gönüllü kişilerle iletişime geçmek için web sitemizin en üstünde ve altında bulunan platform ikonlarına tıklayarak erişebilirsiniz. Ayrıca herkese açık olan proje yönetim araçlarımızı da inceleyebilirsiniz.</p>",
    },
    header2: "Katkı Sağlamak İçin Hangi Yetkinliklere Sahip Olmalısınız?",
  },
  {
    language: { name: "English", code: "en" },
    header: "Who Are We? What Do We Do?",
    paragraph: {
      __html:
        "<p>The Disaster Management System is a platform that facilitates volunteer management and aid coordination in disaster areas. Our aim is to effectively organize volunteers during and after disasters and ensure that aid activities are carried out correctly and safely. Through this platform, post-disaster aid will be delivered quickly and effectively to those in need.</p>\n" +
        "<p>Our platform also provides an open structure where organizations can interact directly. By joining this platform, organizations can access qualified volunteer portfolios and conduct evaluations after events. This will lead to a more efficient and organized structure in disaster management processes.</p>\n" +
        "<p>To contribute to the project, review repositories, and communicate with volunteers, you can access the platform icons at the top and bottom of our website. Additionally, you can explore our project management tools, which are open to everyone.</p>",
    },
    header2: "What Skills Do You Need to Contribute?",
  },
];

export default paragraph;
